import React, {useEffect, useState} from "react";
import styles from './Departments.module.scss'
import {useNavigate} from "react-router-dom";
import Constants from "../../../config";
import axiosInstance from "../../../helpers/api";


export const CompanyDepartments = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const baseURL = Constants.baseUrl
    const [departments, setDepartments] = useState([])
    useEffect(() => {
        getDepartments();
    }, [])
    const getDepartments = async () => {
        try {
            let res = await axiosInstance.get(`departments`)
            console.log(res.data, 'departments')
            setDepartments(res.data)
        } catch (error) {
            // setError(error);
        }
    }

    const handleChangeFile = async (e) => {
        const files = e.currentTarget.files
        if (files) {
            let formData = new FormData();
            formData.append('file', files[0]);
            try {
                let res = await axiosInstance.post(`admin/companies/import`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if(res.status === 201 || res.status === 200) {
                    console.log('uploaded ok')
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    const editDepartment = (data) => {
        navigate('/admin_edit_department', {state: {data}})
    }

    return (
        <div className={`${styles.departments_container} m-0`}>
            <div className={styles.departments_head}>
                <h3 className={styles.departments__title}>All Departments</h3>
                <div className="d-flex">
                    <a href={`${baseURL}companies/sample-csv`}>
                        <div className={styles.departments_head_btn}>Download CSV</div>
                    </a>
                    <div className={styles.departments_head_btn}>
                        <input type="file" onChange={handleChangeFile}/>
                        Import a department</div>
                </div>
            </div>

            {
                departments.length > 0 ?
                    <div className={styles.departments__main_container}>
                        { departments.map((t, i) => {
                            return(
                                <div key={i} className={styles.departments__users_item} onClick={() => editDepartment(t)}>
                                    <div className={styles.user__item_img__container}>
                                        {t.image ?
                                            <img
                                                src={t.image}
                                                alt={t.name}
                                            /> : <div className={styles.user__item_empty}></div>
                                        }
                                    </div>
                                    <h2 className={styles.user__item_position}>{t.name}</h2>
                                </div>
                            )
                        })}
                        <div className={styles.departments__users_item} onClick={() => { navigate('/admin_create_department')}}>
                            <div className={styles.user_team__icon}>
                            </div>
                            <h2 className={styles.user_team}>Create a department</h2>
                        </div>
                    </div>
                    :

                    <div className={styles.departments__main_content}>
                        <h4 className={styles.departments__subtitle}>You don’t have departments yet</h4>
                        <div className={styles.btn__container}>
                            <button className={styles.main_btn} onClick={() => { navigate('/admin_create_department')}}><i className={styles.main_btn__edit_icon}></i>Create a department</button>
                            <button className={styles.main_btn}>
                                <input type="file" onChange={handleChangeFile}/>
                                <i className={styles.main_btn__paper_icon}></i>Import a department
                            </button>
                        </div>
                    </div>
            }
        </div>
    )
}
