import React, {useState, useEffect, useRef} from "react";
import styles from "./TopHeader.module.scss";
import {useNavigate, useLocation} from "react-router-dom";
import axiosInstance from "../../helpers/api";
export const TopHeader = () => {
    const [state, setState] = useState('');
    const [id] = useState(localStorage.getItem('id'));
    const navigate=useNavigate();
    const location = useLocation()

    const [messages, setMessages] = useState([]);
    const collapseRef = useRef(null);
    const wsRef = useRef(null);

    const serverUrl = "wss://ws.peopleup.ch:6001/ws"; // WebSocket server URL
    let channel = `superadmin-notifications.${id}`; // Replace with the channel name
    const [token, setToken ] = useState('') // Replace with your token

    useEffect(() => {

        setState(localStorage.getItem('userRole'))
        if(state == 'company_admin') {
            channel = `admin-notifications.${id}`;
        } else if(state == 'superadmin') {
            channel = `superadmin-notifications.${id}`;
        }
        setToken(localStorage.getItem("token"))
        if(token) {
            getNotifications(state)
        }
        const handleClickOutside = (event) => {
            if (collapseRef.current && !collapseRef.current.contains(event.target)) {
                // Close the collapse (remove the `show` class or manipulate the state)
                document.getElementById("collapseExample").classList.remove("show");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        if(token) {
            const connectWebSocket = () => {
                wsRef.current = new WebSocket(serverUrl);
                wsRef.current.onopen = () => {
                    // console.log("WebSocket connection established");
                    wsRef.current.send(
                        JSON.stringify({
                            action: "subscribe",
                            channel,
                            token,
                        })
                    );
                };
                wsRef.current.onmessage = (event) => {
                    // console.log("Message received from server:", JSON.parse(event.data));
                    let ev
                    ev = JSON.parse(event.data)
                    if(ev.event !== "subscription") {
                        // setMessages((prevMessages) => [
                        //     ...prevMessages,
                        //     `${ev.data}`, // Corrected message format
                        // ]);
                    }
                    // let mess = JSON.parse(event.data)
                    // setMessages(mess, mess => [...mess])

                    if(ev.event === 'admin_notification') {
                        handleMessage([ev.data.message])
                    }
                };
                wsRef.current.onerror = (error) => {
                    // console.error("WebSocket error:", error);
                };
                wsRef.current.onclose = () => {
                    console.log("WebSocket connection closed. Reconnecting...");
                };
            };
            connectWebSocket();
            return () => {
                if (wsRef.current) {
                    wsRef.current.close();
                }
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [channel, token, state , localStorage.getItem('userRole')]);

    const getNotifications = async (status) => {
        let url = ''
        if(status == 'superadmin') {
            url = 'admin/'
        }
        try {
            let res = await axiosInstance.get(`${url}notifications`)
            setMessages([])
                handleMessage(res.data.notifications)
        } catch (error) {
            // setError(error);
        }
    }

    const handleMessage = (data) => {
        setMessages((prevMessages) => [
            ...data,
            ...prevMessages,
        ]);
    }
    const deleteNotification = async (id, role) => {
        try {
            let prefix=(role=='superadmin')?'admin/':'';
            let res = await axiosInstance.delete(`${prefix}notifications/${id}`)
            if(res.status === 200) {
                getNotifications(state)
            }
        } catch (error) {
            // setError(error);
        }
    }
    const markAsRead = async (id) => {
        try {
            let res = await axiosInstance.post(`admin/notifications/${id}/read`)
            if(res.status === 200) {
                getNotifications(state)
            }
        } catch (error) {
            // setError(error);
        }
        // setMessages((prevMessages) =>
        //     prevMessages.map((message) =>
        //         message.id === id ? { ...message, read: true } : message
        //     )
        // );
    };


    //change for color theme
    // var classList = document.documentElement.classList;
    // while (classList.length > 0) {
    //     classList.remove(classList.item(0));
    // }
    // document.documentElement.classList.add("super_admin");
    const logOut = ( ) => {
        localStorage.removeItem('token')
        localStorage.removeItem('userRole')
        localStorage.removeItem('id')
        navigate('/login')
    };
    return(
        <div className={styles.top_bar}>
            <div className={styles.top_bar__logo}>
            </div>
            { !location.pathname.includes('login') &&
            <div className={styles.top_bar__menu_container}>
                { state === 'superadmin' &&
                <div className='d-flex'>
                    <div className={`dropdown ${styles.top_bar__dropdown}`}>
                        <button className="dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            Spotlight
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
                            <li className="dropdown-item" onClick={() => { navigate('/super_spotlight')}}>Spotlight</li>
                            {/*<li className="dropdown-item" onClick={() => { navigate('/admin_all_departments')}}>All Departments</li>*/}
                        </ul>
                    </div>
                    <div className={`dropdown ${styles.top_bar__dropdown}`}>
                        <button className="dropdown-toggle" type="button" id="dropdownMenuButton2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            Company
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton2">
                            <li className="dropdown-item" onClick={() => { navigate('/super_companies')}}>All Companies</li>
                        </ul>
                    </div>
                </div>
                }

                { state === 'company_admin' &&
                    <div className="d-flex">
                        <div className={styles.top_bar__link} onClick={() => { navigate('/admin_spotlight')}}>Spotlight</div>
                        <div className={`dropdown ${styles.top_bar__dropdown}`}>
                            <button className="dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                Departments
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li className="dropdown-item" onClick={() => { navigate('/admin_departments')}}>All departments</li>
                            </ul>
                        </div>
                        <div className={styles.top_bar__link}>Managers</div>
                        <div className={styles.top_bar__link} onClick={() => {navigate('/admin_teams')}}>Team</div>
                        <div className={styles.top_bar__link} onClick={() => {navigate('/admin_employee')}}>Employee</div>
                        <div className={styles.top_bar__link} onClick={() => {navigate('/admin_positions')}}>Positions</div>
                    </div>
                }

                {/*<div className={`dropdown ${styles.top_bar__dropdown}`}>*/}
                {/*    <button className="dropdown-toggle" type="button" id="dropdownMenuButton1"*/}
                {/*            data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*        Departments*/}
                {/*    </button>*/}
                {/*    <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">*/}
                {/*        <li className="dropdown-item" onClick={() => { navigate('/admin_hr')}}>HR</li>*/}
                {/*        <li className="dropdown-item">Managers</li>*/}
                {/*        <li className="dropdown-item">Sales</li>*/}
                {/*        <li className="dropdown-item">Developers</li>*/}
                {/*        <li className="dropdown-item">Designers</li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                {/*<div className={styles.top_bar__menu_item}>Managers</div>*/}
                {/*<div className={styles.top_bar__menu_item}>Employees</div>*/}

            </div>
            }
            { !location.pathname.includes('login') &&
            <div className={styles.top_bar__right}>
                <div className={styles.input__container}>
                    <span className={styles.input__icon}></span>
                    <input type="text" placeholder={`Search`}/>
                </div>

                {/*<div className={styles.top_bar__bell_logo}></div>*/}
                {/*<div className={`dropdown-toggle ${styles.top_bar__bell_logo}`}  id="dropdownMenuButton1"*/}
                {/*     data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">*/}
                {/*    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">*/}
                {/*        <li className="dropdown-item">Account</li>*/}
                {/*        <li><a className="dropdown-item" href="/">Another action</a></li>*/}
                {/*        <li><a className="dropdown-item" href="/" onClick={() => { logOut()}}>Log out</a></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                <div className={`${styles.top_bar__bell_logo}`} >
                    <a className={styles.top_bar__bell_logo_link} data-toggle="collapse" href="#collapseExample" role="button"
                       aria-expanded="false" aria-controls="collapseExample">
                    </a>
                <div className={`collapse ${styles.top_bar__bell_logo_collapse}`} id="collapseExample" ref={collapseRef}>
                    <a href="#collapseExample" data-toggle="collapse" aria-controls="collapseExample">X</a>
                    <div className="card card-body">
                        <ul className={styles.top_bar__bell_logo_list}>
                            {messages.map((message, index) => (
                                <li onClick={() => markAsRead(message.id)} className={`${styles.top_bar__bell_logo_list__item} ${message.read_at != null ? styles.top_bar__bell_logo_list__item_read : ""}`} key={index}>
                                    {`${message.data.message}`}
                                    <span className={styles.top_bar__bell_logo_list_delete} onClick={(e) => {e.stopPropagation(); deleteNotification(message.id)}}>X</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                </div>

                <div className={styles.top_bar__user_logo}></div>
                <div className={`dropdown ${styles.top_bar__dropdown}`}>
                    <button className="dropdown-toggle mx-1" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li className="dropdown-item">Account</li>
                        <li><a className="dropdown-item" href="/">Another action</a></li>
                        <li><a className="dropdown-item" href="/" onClick={() => { logOut()}}>Log out</a></li>
                    </ul>
                </div>
            </div>
            }
        </div>
    )
}
