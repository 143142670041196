
import './App.css';
import {BrowserRouter as Router, Route, Routes, Navigate  } from "react-router-dom";
// import {HomePage} from "./pages/HomePage/HomePage";
// import {CallCenter} from "./pages/CallCenter/CallCenter";
// import {Settings} from "./pages/Settings/Settings";
import RoleGuard from "./helpers/RoleGuard";
import {LogIn} from "./pages/LogIn/LogIn";
import Layout from "./components/Layout/Layout";

import {SuperSpotlight} from "./pages/admin/Spotlight/Spotlight";


import {CompanySpotlight} from "./pages/company_admin/CompanySpotlight/Spotlight";
import {CompanyDepartments} from "./pages/company_admin/CompanyDepartments/Departments";
import {CompanyAllDepartments} from "./pages/company_admin/CompanyAllDepartments/AllDepartments";
import {CompanyCreateDepartment} from "./pages/company_admin/CompanyCreateDepartment/CreateDepartment";

import {ManagerSpotlight} from "./pages/manager/Spotlight/Spotlight";

import {useState} from "react";
import {SuperCompanies} from "./pages/admin/SuperCompanies/SuperCompanies";
import {SuperCreateCompany} from "./pages/admin/SuperCreateCompany/SuperCreateCompany";
import {SuperCreateAdmin} from "./pages/admin/SuperCreateAdmin/SuperCreateAdmin";
import {SuperEditCompany} from "./pages/admin/SuperEditCompany/SuperEditCompany";
import {SuperEditAdmin} from "./pages/admin/SuperEditAdmin/SuperEditAdmin";
import {CompanyEditDepartment} from "./pages/company_admin/CompanyEditDepartment/CompanyEditDepartment";
import {CompanyTeams} from "./pages/company_admin/CompanyTeams/CompanyTeams";
import {CompanyCreateTeam} from "./pages/company_admin/CompanyCreateTeam/CompanyCreateTeam";
import {CompanyEmployee} from "./pages/company_admin/Company Employee/CompanyEmployee";
import {CompanyEditTeam} from "./pages/company_admin/CompanyEditTeam/CompanyEditTeam";
import {CompanyCreateEmployee} from "./pages/company_admin/CompanyCreateEmployee/CompanyCreateEmployee";
import {CompanyPositions} from "./pages/company_admin/CompanyPositions/CompanyPositions";

function App() {
    const [state, setState] = useState(localStorage.getItem('userRole'));
  return (
      <Router>
          <Routes>

              {/*<Route path='/login' element={<LogIn/>}/>*/}
              {/*<Route path="/" element={<Navigate to="/home"/>}/>*/}
              {/*<Route path='/login' element={<Layout><LogIn /></Layout>} />*/}


              <Route path="/"  element={<Layout />}>
                  <Route path='/login' element={<LogIn changeRole={setState}/>} />

                  {/*Start Super Admin*/}
                  <Route
                      path="/super_companies"
                      element={
                          <RoleGuard allowedRoles={['superadmin']} userRole={state}>
                              <SuperCompanies  />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/super_spotlight"
                      element={
                          <RoleGuard allowedRoles={['superadmin']} userRole={state}>
                              <SuperSpotlight  />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/super_create_company"
                      element={
                          <RoleGuard allowedRoles={['superadmin']} userRole={state}>
                              <SuperCreateCompany  />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/super_create_admin"
                      element={
                          <RoleGuard allowedRoles={['superadmin']} userRole={state}>
                              <SuperCreateAdmin  />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/super_edit_company"
                      element={
                          <RoleGuard allowedRoles={['superadmin']} userRole={state}>
                              <SuperEditCompany  />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/super_edit_admin"
                      element={
                          <RoleGuard allowedRoles={['superadmin']} userRole={state}>
                              <SuperEditAdmin  />
                          </RoleGuard>
                      }
                  />
                  {/*End Super Admin*/}




                  {/*Start Company Admin*/}
                  <Route
                      path="/admin_spotlight"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanySpotlight  />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_departments"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyDepartments  />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_all_departments"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyAllDepartments />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_create_department"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyCreateDepartment />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_edit_department"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyEditDepartment  />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_teams"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyTeams />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_create_team"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyCreateTeam />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_edit_team"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyEditTeam />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_employee"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyEmployee />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_create_employee"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyCreateEmployee />
                          </RoleGuard>
                      }
                  />
                  <Route
                      path="/admin_positions"
                      element={
                          <RoleGuard allowedRoles={['company_admin']} userRole={state}>
                              <CompanyPositions />
                          </RoleGuard>
                      }
                  />
                  {/*End Company Admin*/}

                  <Route
                      path="/manager"
                      element={
                          <RoleGuard allowedRoles={['manager']} userRole={state}>
                              <ManagerSpotlight />
                          </RoleGuard>
                      }
                  />
                  <Route path="/" element={<Navigate to="/login" />} />

                  {/*<Route index path='/' element={<HomePage/>}/>*/}
                  {/*<Route  path='/departments' element={<Departments/>}/>*/}
                  {/*<Route  path='/spotlight' element={<Spotlight/>}/>*/}
                  {/*<Route path='/account' element={<Account />} />*/}
                  {/*<Route path='/hr' element={<Hr />} />*/}
                  {/*<Route path='/call_center' element={<CallCenter />} />*/}
                  {/*<Route path='/settings' element={<Settings />} />*/}
              </Route>
          </Routes>
      </Router>
  );
}

export default App;
