import React, {useEffect, useState} from "react";
import styles from './CompanyEmployee.module.scss'
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../helpers/api";

export const CompanyEmployee =() => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [employee, setEmployee] = useState([])
    useEffect(() => {
        getEmployee();
    }, [])
    const getEmployee = async () => {
        try {
            let res = await axiosInstance.get(`company-admin/users?role=collaborator`)
            setEmployee(res.data)
        } catch (error) {
            // setError(error);
        }
    }
    const editEmployee = (data) => {
        // navigate('/admin_edit_team', {state: {data}})
    }

    return (
        <div className={`${styles.teams_container} m-0`}>
            <div className={styles.teams_head}>
                <h3 className={styles.teams__title}>All Employee</h3>
            </div>
            <div className={styles.teams__main_container}>
                { employee.map((t, i) => {
                    return(
                        <div key={i} className={styles.teams__users_item} onClick={() => editEmployee(t)}>
                            <div className={styles.user__item_img__container}>
                                {t.image ?
                                    <img
                                        src={t.image}
                                        alt={t.first_name}
                                    /> : <div className={styles.user__item_empty}></div>
                                }
                            </div>
                            <h2 className={styles.user__item_position}>{t.first_name}&nbsp;{t.last_name}</h2>
                        </div>
                    )
                })}
                <div className={styles.teams__users_item} onClick={() => { navigate('/admin_create_employee')}}>
                    <div className={styles.user_team__icon}>
                    </div>
                    <h2 className={styles.user_team}>Create an employee</h2>
                </div>
            </div>

        </div>
    )
}
