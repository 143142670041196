import React, {useState} from "react";
import styles from './Accoint.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useNavigate} from "react-router-dom";


export const CompanyCreateDepartment = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [selectedImage, setSelectedImage] = useState('');
    const setMyValue = (e) => {
        console.log(e, 'gggg')
    };
    const { register, setError, handleSubmit, formState: { errors }, } = useForm()
    const onSubmit = async (data) => {

        let formData = new FormData();
        formData.append('activity', data.activity);
        formData.append('email', data.email);
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        selectedImage && formData.append('image', selectedImage);
        console.log(data, 'send')

        try {
            let res = await axiosInstance.post(`departments`, formData)
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_departments')
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); // Set the base64 image data
            };
            reader.readAsDataURL(image); // Convert image to base64 format
        }
    }
    return(
        <div className={styles.page__content}>
            <h1 className={styles.page__title}>Department</h1>
            <div className={styles.page__data_block}>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={styles.page__data_img__container}>
                        <div className={styles.page__data_img__icon}>
                            <input type="file" accept="image/*" onChange={onFileChange} />
                        </div>
                        {selectedImage && (
                            <div className={styles.page__data_img_selected}>
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                />
                            </div>
                        )}
                    </div>

                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Department name</label>
                                <Input input={{
                                    placeholder: "Candidate’s name",
                                    name: "name",
                                    type: "text",
                                    ...register("name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.name && <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Email address</label>
                                <Input input={{
                                    placeholder: "companyname@gmail.com",
                                    name: "email",
                                    type: "email",
                                    ...register("email", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.email?.message ?
                                    <span className='textDanger'>{errors.email.message}</span> :
                                    errors.email &&
                                    <span className='textDanger'>This field is required</span>}
                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Activity</label>
                                <select className="form-control" id="activity" {...register("activity")} onChange={(e) => setMyValue(e.target.value)}
                                        defaultValue={'1'}>
                                    <option selected disabled value='1'>activity1</option>
                                    <option value='2'>activity2</option>
                                    <option value='3'>activity3</option>
                                    <option value='4'>activity4</option>
                                    <option value='5'>activity5</option>
                                </select>
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                <Input input={{
                                    placeholder: "+87 5469 554 78",
                                    name: "phone",
                                    type: "number",
                                    ...register("phone", { required: true })
                                }}
                                       className="form-control"
                                />
                            </div>
                        </div>
                        <div className={styles.page__done_container}>
                            <button className={styles.page__cancel_btn} onClick={() => { navigate('/admin_departments')}}>Cancel</button>
                            <button className={styles.page__done_btn} type="submit">Done</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
