import React, {useEffect, useState} from "react";
import styles from './CompanyEditDepartment.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useLocation, useNavigate} from "react-router-dom";
import {MigrateModal} from "../../../UI/Modal/Modal";

export const CompanyEditDepartment = (props) => {
    document.documentElement.classList.add('admin')
    const location = useLocation()
    const navigate=useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [changedImg, setChangedImg] = useState( null);
    const [migrateModalIsOpen, setMigrateModalIsOpen] = useState(false);
    const [editId, setEditId] = useState([]);

    const { register,setError, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: location.state.data ? {
                name: location.state.data.name,
                email: location.state.data.email,
                phone: location.state.data.phone,
                activity: location.state.data.activity,
            }
            : undefined,
    })
    useEffect(() => {
        setEditId(location.state.data.id, [location.state.data.id])
        setSelectedImage(location.state.data.image,[ location.state.data.image])
    }, [location.state.data.id, location.state.data.logo ])
    const setMyValue = (e) => {
        console.log(e, 'gggg')
    };
    const cancelEdit = () => {
        navigate('/admin_departments')
    }
    const onSubmit = async (data) => {
        let formData = new FormData();
        formData.append('activity', data.activity);
        formData.append('email', data.email);
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        formData.append('id', editId);

        if(changedImg !== null) {
            formData.append('image', changedImg);
        }
        try {
            let res = await axiosInstance.put(`departments/${editId}`, formData)
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_departments')
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setChangedImg(reader.result); // Set the base64 image data
            };
            reader.readAsDataURL(image); // Convert image to base64 format
        }
    }
    const deleteDepartment = () => {
        setMigrateModalIsOpen(true);
    }
    const delDepartment = async () => {
        try {
            let res = await axiosInstance.delete(`departments/${editId}`)
            if(res.status === 200 || res.status === 201 ) {
                closeMigrateModal()
                navigate('/admin_departments')
            }
        } catch (error) {
            setError(error);
        }
    }
    function closeMigrateModal(){
        setMigrateModalIsOpen(false);
    }
    return(
        <div className={styles.page__content}>
            <div className={styles.page__head_container}>
                <h1 className={styles.page__title}>Edit Department</h1>
                <div className={styles.page__head_btn__container}>
                    <button className={styles.page__head_btn__delete} onClick={() => deleteDepartment()}>Delete Department</button>
                </div>
            </div>
            <div className={styles.page__data_block}>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={styles.page__data_img__container}>
                        <div className={styles.page__data_img__icon}>
                            <input type="file" accept="image/*" onChange={onFileChange} />
                        </div>
                        <div className={styles.page__data_img_selected}>
                            {selectedImage &&
                            <img
                                src={selectedImage}
                                alt="Selected"
                            />
                            }
                            {changedImg &&
                            <img
                                src={changedImg}
                                alt="Selected"
                                className={styles.page__data_img_changed}
                            />
                            }
                        </div>
                    </div>

                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Department name</label>
                                <Input input={{
                                    placeholder: "Candidate’s name",
                                    name: "name",
                                    type: "text",
                                    ...register("name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.name && <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Email address</label>
                                <Input input={{
                                    placeholder: "companyname@gmail.com",
                                    name: "email",
                                    type: "email",
                                    ...register("email", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.email  && <span className='textDanger'>This field is required</span>}
                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Activity</label>
                                <select className="form-control" id="activity" {...register("activity")} onChange={(e) => setMyValue(e.target.value)}
                                        defaultValue={'1'}>
                                    <option disabled value='1'>activity1</option>
                                    <option value='2'>activity2</option>
                                    <option value='3'>activity3</option>
                                    <option value='4'>activity4</option>
                                    <option value='5'>activity5</option>
                                </select>
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                <Input input={{
                                    placeholder: "+87 5469 554 78",
                                    name: "phone",
                                    type: "number",
                                    ...register("phone", { required: true })
                                }}
                                       className="form-control"
                                />
                            </div>
                        </div>
                        <div className={styles.page__done_container}>
                            <button className={styles.page__cancel_btn} onClick={() => cancelEdit()}>Cancel</button>
                            <button className={styles.page__done_btn} type="submit">Edit</button>
                        </div>
                    </div>
                </form>

                <MigrateModal
                    closeMigrateModal = {closeMigrateModal}
                    migrateModalIsOpen ={migrateModalIsOpen}
                    onSuccess={delDepartment}
                    data ={
                        {
                            title: 'Delete department',
                            text: 'Are you sure you want to delete a department?',
                            button_info1: 'No',
                            button_info2: 'Yes',}
                    }
                />
            </div>
        </div>
    )

}
