import React, {useState} from "react";
import styles from './CompanyCreateTeam.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useNavigate} from "react-router-dom";

export const CompanyCreateTeam = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [selectedImage, setSelectedImage] = useState('');

    const { register, setError, handleSubmit, formState: { errors }, } = useForm()
    const onSubmit = async (data) => {

        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('manager_id', data.manager_id);
        formData.append('department_id', 2);
        selectedImage && formData.append('image', selectedImage);
        console.log(data, 'send')

        try {
            let res = await axiosInstance.post(`teams`, formData)
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_teams')
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); // Set the base64 image data
            };
            reader.readAsDataURL(image); // Convert image to base64 format
        }
    }
    return (
        <div className={styles.page__content}>
            <h1 className={styles.page__title}>Teams</h1>
            <div className={styles.page__data_block}>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={styles.page__data_img__container}>
                        <div className={styles.page__data_img__icon}>
                            <input type="file" accept="image/*" onChange={onFileChange} />
                        </div>
                        {selectedImage && (
                            <div className={styles.page__data_img_selected}>
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                />
                            </div>
                        )}
                    </div>

                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Team name</label>
                                <Input input={{
                                    placeholder: "Candidate’s name",
                                    name: "name",
                                    type: "text",
                                    ...register("name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.name && <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Our moto</label>
                                <Input input={{
                                    placeholder: "Our moto",
                                    name: "moto",
                                    type: "text",
                                    ...register("moto", { required: false })
                                }}
                                       className="form-control"
                                />

                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>

                            <div className={styles.form_group}>
                                <label htmlFor="exampleFormControlInput1">Number of peoples</label>
                                <Input input={{
                                    placeholder: "Number",
                                    name: "manager_id",
                                    type: "number",
                                    ...register("manager_id", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.manager_id ?
                                    <span className='textDanger'>{errors.manager_id[0]}</span> :
                                    errors.manager_id &&
                                    <span className='textDanger'>This field is required</span>}
                            </div>
                        </div>
                        <div className={styles.page__done_container}>
                            <button className={styles.page__cancel_btn} onClick={() => { navigate('/admin_teams')}}>Cancel</button>
                            <button className={styles.page__done_btn} type="submit">Done</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
