import React, {useEffect, useState} from "react";
import styles from './CompanyTeams.module.scss'
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../helpers/api";

export const CompanyTeams =() => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [teams, setTeams] = useState([])
    useEffect(() => {
        getTeams();
    }, [])
    const getTeams = async () => {
        try {
            let res = await axiosInstance.get(`teams`)
            setTeams(res.data)
        } catch (error) {
            // setError(error);
        }
    }
    const editTeam = (data) => {
        navigate('/admin_edit_team', {state: {data}})
    }

    return (
        <div className={`${styles.teams_container} m-0`}>
            <div className={styles.teams_head}>
                <h3 className={styles.teams__title}>All Teams</h3>
            </div>
                <div className={styles.teams__main_container}>
                    { teams.map((t, i) => {
                        return(
                            <div key={i} className={styles.teams__users_item} onClick={() => editTeam(t)}>
                                <div className={styles.user__item_img__container}>
                                    {t.image ?
                                        <img
                                            src={t.image}
                                            alt={t.name}
                                        /> : <div className={styles.user__item_empty}></div>
                                    }
                                </div>
                                <h2 className={styles.user__item_position}>{t.name}</h2>
                            </div>
                        )
                    })}
                    <div className={styles.teams__users_item} onClick={() => { navigate('/admin_create_team')}}>
                        <div className={styles.user_team__icon}>
                        </div>
                        <h2 className={styles.user_team}>Create a team</h2>
                    </div>
                </div>

        </div>
    )
}
