import React from "react";
import styles from "./AllDepartments.module.scss"
import {useNavigate} from "react-router-dom";
export const CompanyAllDepartments = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    return(
        <div className={ styles.page__container}>
            <h1 className={styles.page__title}>Departments</h1>
            <div className={styles.page__users}>

                <div className={styles.page__users_item}>
                    <span className={styles.user__item_dots}></span>
                    <div className={styles.user__item_img__container}>
                        <img src={require('../../../assets/images/hr_image.png')} alt=""/>
                    </div>
                    <h2 className={styles.user__item_position}>HR</h2>
                </div>

                <div className={styles.page__users_item} onClick={() => { navigate('/admin_departments')}}>
                    <div className={styles.user_team__icon}>
                    </div>
                    <h2 className={styles.user_team}>Create a team</h2>
                </div>
            </div>

        </div>
    )
}
